import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { ListItem, Pagination } from "@molecules";
import { Container, Dropdown, Text } from "@atoms";
import { m, AnimatePresence } from "framer-motion";

const ArticleList = React.memo(({ items, className: _className }) => {
  const lang = useLang();
  const topics = [
    { title: "All", uid: "0" },
    ...useCategoriesData()[lang === "en" ? "en" : "es"].topics,
  ];
  const types = [
    { uid: "all", title: "All" },
    { uid: "article", title: "Article" },
  ];

  const [type, setType] = useState(0);
  const [topic, setTopic] = useState(0);
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter(
        item =>
          (type === 0 || item.type === types[type]?.uid) &&
          (topic === 0 || item.topics?.includes(topics[topic]?.uid))
      )
    );
  }, [type, topic]);

  // set topic based on the url parameter
  // const urlFilter =
  //   typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;

  // useEffect(() => {
  //   if (urlFilter) {
  //     setTopic(topics?.findIndex(top => top.uid === urlFilter));
  //   }
  // }, [urlFilter]);

  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 50,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <section className={classNames("py-20 md:py-28", _className)}>
      <Container variant="xs">
        {/* filters */}
        <div id="topOfList" className="flex flex-wrap gap-4">
          <div className="flex w-full items-center space-x-2 sm:w-auto">
            <Text variant="label" className="text-gray-dark">
              Type
            </Text>
            <Dropdown model={[type, setType]} options={types} size="sm" />
          </div>
          <div className="flex w-full items-center space-x-2 sm:w-auto">
            <Text variant="label" className="text-gray-dark">
              Topic
            </Text>
            <Dropdown model={[topic, setTopic]} options={topics} size="sm" />
          </div>
        </div>
        {/* filtered items */}
        <div className="mt-12 flex flex-col gap-12">
          <AnimatePresence mode="wait">
            {!!filteredItems?.length && (
              <Pagination
                component={ListItem}
                data={filteredItems}
                pageSize={5}
                rangeLimit={2}
                showNavigation={false}
                variants={variants}
                className="grid w-full grid-cols-1 gap-12"
                scrollTarget="topOfList"
              />
            )}
            {!filteredItems?.length && (
              <m.li
                key="no-results"
                initial="exit"
                animate="enter"
                exit="exit"
                variants={variants}
                className="flex flex-col items-center justify-center md:mt-20"
              >
                <Text variant="xl">
                  {t(
                    "There are no items that match your selected filters.",
                    lang
                  )}
                </Text>
              </m.li>
            )}
          </AnimatePresence>
        </div>
      </Container>
    </section>
  );
});

ArticleList.defaultProps = {};

export default ArticleList;
